import {Container} from "react-bootstrap";
import React from "react";
import {useApplicationContext} from "../context/AppContext";
import Question from "./Question";

const Questions = ({canShow}) => {
    const {
        vendorQuestions,
        canShowItem,
        findVendorQuestionById,
        auth
    } = useApplicationContext();

    const formType = auth.authToken.formType;

    const form = vendorQuestions.formType.find((item) => {
        return item.name === formType ? item : null;
    })

    return (
      form.sections.map((section) => {
                return canShowItem(section) &&

                    section.questions.map((question, index) => {
                            const formQuestion = section.questions.find((item) => {
                                return item.id === question.id ? item : null;
                            })

                            const vendorQuestion = findVendorQuestionById(question.id);
                            return canShow(formQuestion, vendorQuestion) &&
                                <div className={"mb-5 question"} key={question.id} id={question.id} tabIndex="-1">
                                    <Container>
                                        <Question section={section}  index={index} vendorQuestion={vendorQuestion} formQuestion={formQuestion} />
                                    </Container>
                                </div>
                        }
                    )

            }
        )
    )
}

export default Questions;