import {Button, Col, Container, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {InfoCircleFill} from "react-bootstrap-icons";
import React from "react";
import {useApplicationContext} from "../context/AppContext";
import ResultsBackButton from "./ResultsBackButton";
import ResultsCTARenderer from "./ResultsCTARenderer";

const ResultsContainer = () => {
    const {
        results,
        biggestConcerns
    } = useApplicationContext();

    const getFeelingBasedOfResults = () => {
        if (results <= 25) {
            return "having trouble";
        } else if (results <= 50) {
            return "just coping";
        } else if (results <= 75) {
            return "getting by";
        } else {
            return "doing great";
        }
    }

    const getProgressClassBasedOffResults = () => {
        if (results <= 25) {
            return "bg-danger";
        } else if (results <= 50) {
            return "bg-warning";
        } else if (results <= 75) {
            return "bg-info";
        } else {
            return "bg-success";
        }
    }

    const concernInfoText  = (biggestConcern) => (
        <Popover id="popover-basic">
            <Popover.Title as="h1" className={"text-capitalize"}>{biggestConcern}</Popover.Title>
            <Popover.Content>
                {
                    biggestConcern === 'every day' ? <span>
                        Every Day finances looks at how you are managing your day-to-day finances like rent or mortgage, transport costs and bills.
                    </span> : biggestConcern === 'rainy day' ? <span>
                        Rainy Day finances looks at how ready you are to deal with the unexpected, and whether you have an emergency fund just in case something happens - like your car needing repairs.
                    </span> : <span>
                        One Day finances is about reaching your financial goals, making the most of your money and setting up your future.
                    </span>
                }
            </Popover.Content>
        </Popover>
    );

    const getBiggestConcernsText = () => {
        const items = [];

        items.push(<span key={0}>From your answers, it appears that </span>);
        if (biggestConcerns.length === 1) {
            items.push(<span key={1}>your biggest area of concern is <strong>{biggestConcerns[0]} finances <OverlayTrigger
                trigger={['click']} placement="top" overlay={concernInfoText(biggestConcerns[0])}><Button data-testid="overlay-0" variant="link" size="sm" className="p-0"><span className="sr-only">Click this link for an explanation</span><InfoCircleFill className="mr-1" /></Button>
                                                                                                     </OverlayTrigger></strong></span>)
        } else {
            //never show all three concerns
            items.push(<span key={1}>your biggest areas of concern are <strong>{biggestConcerns[0]} finances <OverlayTrigger
                trigger={['click']} placement="top" overlay={concernInfoText(biggestConcerns[0])}><Button data-testid="overlay-0" variant="link" size="sm" className="p-0"><span className="sr-only">Click this link for an explanation</span><InfoCircleFill className="mr-1" /></Button>
                                                                                                     </OverlayTrigger></strong> and <strong>{biggestConcerns[1]} finances  <OverlayTrigger
                trigger={['click']} placement="top" overlay={concernInfoText(biggestConcerns[1])}><Button data-testid="overlay-1" variant="link" size="sm" className="p-0"><span className="sr-only">Click this link for an explanation</span><InfoCircleFill className="mr-1" /></Button>
                                                                                                     </OverlayTrigger></strong></span>)
        }

        return items;

    }

    return (
        <Container>
            <h1>MoneyPulse check-up summary</h1>
            <h2>Here are your results!</h2>
            <div className="well">
                <p>
                    Based on your answers, you feel you are <span
                    className={"results-value"}>{getFeelingBasedOfResults()} ({results} out of 100)</span>
                </p>
                <div className="progress">
                    <div
                        data-testid={"progress-bar"}
                        className={"progress-bar progress-bar-striped progress-bar-animated " + getProgressClassBasedOffResults()}
                        role="progressbar" style={{width: results + "%"}} aria-valuenow={results} aria-valuemin="0"
                        aria-valuemax="100"/>
                </div>

                <Row className="flex-nowrap">
                    <Col className="progress-caption"><small>Having trouble</small><span className="sr-only">Scores between 0 and 25 are rated as "having trouble".</span>
                    </Col>
                    <Col className="progress-caption"><small>Just coping</small><span className="sr-only">Scores between 26 and 50 are rated as "just coping".</span>
                    </Col>
                    <Col className="progress-caption"><small>Getting by</small><span className="sr-only">Scores between 51 and 75 are rated as "getting by".</span>
                    </Col>
                    <Col className="progress-caption"><small>Doing great</small><span className="sr-only">Scores between 76 and 100 are rated as "doing great".</span>
                    </Col>
                </Row>


                {biggestConcerns.length > 0 &&

                <p className="mt-5 concerns">
                    {getBiggestConcernsText()}
                </p>

                }
            </div>
            <ResultsBackButton />

            <h2>Thank You!</h2>

            <p>
                Thank you for taking the time to think about your financial wellbeing. Your score gives you a sense of
                your overall level of comfort with your financial circumstances. And, if areas of concern are highlighted,
                that's a good place to focus when you think about exploring how you might improve your financial wellbeing.
            </p>

            <ResultsCTARenderer />

            <h3>Paying it forward</h3>
            <p>
                As well as your survey delivering personalised feedback to you, the anonymised data from your survey
                and the surveys of other borrowers will help Verifier, our academic partners, and our lender partners to learn what drives great customer outcomes.
                In other words, you will help all borrowers!
            </p>

        </Container>
    )
};

export default ResultsContainer;