import React, {useEffect, useState} from 'react'
import {Button, Container, Form} from "react-bootstrap";
import {useApplicationContext} from "../context/AppContext";
import {useHistory} from "react-router-dom";
import {ArrowUp} from "react-bootstrap-icons";
import Questions from "./Questions";
import {useSweetAlertContext} from "../context/SweetAlertContext";

const Questionnaire = () => {
    const [submitting, setSubmitting] = useState(false);

    const history = useHistory();

    const {
        vendorQuestions,
        canShowItem,
        findVendorQuestionById,
        allQuestions,
        saveEvent,
        auth,
        setBiggestConcerns,
        setResults,
        calculateScoresForEachDomain,
        calculateBiggestConcerns,
        displayError,
        dispatchAutoScrollEvent,
        addJob,
        flush,
        jobFactory
    } = useApplicationContext();

    const {setSweetAlertConfig} = useSweetAlertContext();

    const calculateAnswers = () => {
        const answers = {
            results: {
                score: ''
            },
            questions: []
        }

        const formType = auth.authToken.formType;

        const form = vendorQuestions.formType.find((item) => {
            return item.name === formType ? item : null;
        })


        form.sections.forEach((section) => {
            if (canShowItem(section)) {

                section.questions.forEach((question, index) => {
                    const formQuestion = section.questions.find((item) => {
                        return item.id === question.id ? item : null;
                    })

                    const vendorQuestion = findVendorQuestionById(question.id);

                    if (canShow(formQuestion, vendorQuestion)) {

                        let answer = null;
                        let freeFormTextFieldValue = null;

                        if (!Array.isArray(vendorQuestion.score)) {
                            const foundItem = vendorQuestion.answers && vendorQuestion.answers.find((ans) => {
                                return ans.value === vendorQuestion.score
                            })

                            answer = foundItem ? foundItem.label : null;

                            if(foundItem && foundItem.freeFormTextFieldRequired){
                                freeFormTextFieldValue = vendorQuestion.freeFormTextFieldValue;
                            }
                        } else {
                            //if its an array we need to find the item in vendorQuestion.score that has freeFormTextFieldRequired = true

                            const foundItem = vendorQuestion.score.find((ans) => {
                                return ans.freeFormTextFieldRequired === true
                            })

                            if(foundItem){
                                foundItem.other = vendorQuestion.freeFormTextFieldValue;
                            }
                        }

                        const question = {
                            id: vendorQuestion.id,
                            leadQuestion: vendorQuestion.leadQuestion,
                            question: vendorQuestion.question,
                            answer: Array.isArray(vendorQuestion.score) ? vendorQuestion.score : answer,
                            result: !Array.isArray(vendorQuestion.score) ? vendorQuestion.score : null
                        }

                        if(freeFormTextFieldValue){
                            question.other = freeFormTextFieldValue;
                        }

                        answers.questions.push(question);
                    }
                })
            }
        })

        return answers;
    }

    const removeDialog = () => {
        setSweetAlertConfig(prevState => {
            return {
                ...prevState, ...{
                    type: '',
                    message: '',
                    open: false
                }
            };
        })
    }

    const displayResults = () => {

        const answers = calculateAnswers();

        if (allQuestions) {
            const domainValues = calculateScoresForEachDomain();

            const concernValues = calculateBiggestConcerns(domainValues)

            setBiggestConcerns(concernValues);

            setResults(domainValues.score * 5)

            answers.results.score = domainValues.score >= 0 ? domainValues.score * 5 : -1;
            answers.results.areasOfConcern = domainValues.score >= 0 ? concernValues.length > 0 ? concernValues : ["every day", "rainy day", "one day"] : [];
        }

        addJob(jobFactory('submit', 'interaction'));

        addJob(jobFactory(null, 'answers', null, null, null, null, answers));

       const displayDialog = setTimeout(() => {
                setSweetAlertConfig(prevState => {
                    return {
                        ...prevState, ...{
                            open: true,
                            title: 'Please wait performing action',
                            type: 'success',
                            message: '',
                            timeout: 0
                        }
                    };
                })
        }, 500)


       flush(() => {
             clearTimeout(displayDialog);
             removeDialog();
             history.push(`/${auth.customerId}/results`);
         }, (error, queue) => {
            clearTimeout(displayDialog);
            removeDialog();
            const index = queue.findIndex((j) => j.type === 'submit');
            if(index){
               //we want to delete both the submit job and the answers job straight afterwards
               queue.splice(index,2);
            }

            setSubmitting(false);
            displayError(error);
         });
    }

    useEffect(() => {
        if(submitting){
            displayResults();
        }
        // eslint-disable-next-line
    },[submitting])


    //validation has to check that all visible fields have been filled
    const isFormValid = () => {
        let isValid = true;

        if (allQuestions) {

            allQuestions.forEach((ques) => {

                const question = findVendorQuestionById(ques.id);

                if (canShowItem(ques)) {
                    if (question.score === "" || (Array.isArray(question.score) && question.score.length === 0)) {
                        isValid = false;
                    }

                    if (isValid) {
                        isValid = checkThatOtherFreeFormTextfieldIsValid(question);
                    }
                }
            })
        }

        return isValid;
    }

    const checkThatOtherFreeFormTextfieldIsValid = (question) => {
        let isValid = true;

        if (question.hasOwnProperty("freeFormTextFieldValue")) {
            let foundItem;

            if (!Array.isArray(question.score)) {
                //find the answer that contains the freeFormTextFieldRequired value
                foundItem = question.answers.find((ans) => {
                    return ans.hasOwnProperty("freeFormTextFieldRequired") && ans.value === question.score
                })
            } else {
                foundItem = question.score.find((itm) => {
                    return itm.freeFormTextFieldRequired
                })
            }

            if (foundItem && question.freeFormTextFieldValue.trim().length === 0) {
                isValid = false;
            }
        }

        return isValid;
    }


    const canShow = (formQuestion, vendorQuestion) => {
        return formQuestion && vendorQuestion && vendorQuestion.showInForm && canShowItem(formQuestion);
    }

    return (

        <Form className={"flex-element"}>
            {allQuestions && <Questions canShow={canShow}/>}

            <div className={"mb-5 question"} tabIndex={"-1"}>
                <Container>
                    <h2>Happy with all your answers?</h2>
                    <p className="lead">If so, you're ready to see your results on the next page.</p>

                    <div className={"well"}>
                        <Button data-testid="resultsBtn" id="resultsBtn" variant={"primary"} size="lg"
                                onClick={() => setSubmitting(true)} disabled={!isFormValid() || submitting}>Continue</Button>
                    </div>
                    <button type={"button"} className="in-page-nav" onClick={() => {
                        const aQs = JSON.parse(JSON.stringify(allQuestions));
                        const el = aQs.pop();

                        dispatchAutoScrollEvent(`#${el.id}`);
                        saveEvent('previous');
                    }}><ArrowUp className="mr-1"/>Previous
                    </button>
                </Container>
            </div>
        </Form>
    )
}

export default Questionnaire;