import React from 'react';
import {Redirect, Route, useHistory} from 'react-router-dom';
import {useApplicationContext} from "./context/AppContext";
import validator from "validator";

const PrivateRoute = ({component: Component, ...rest}) => {
    const history = useHistory();
    const pathname = rest.location.pathname;
    const parts = pathname.split('/');
    const id = parts[1];

    if(!validator.isUUID(id)){
        history.push(`/${id}/error`);
    }

    const {auth} = useApplicationContext();

    return (
        <Route {...rest} render={props => (
            (auth && auth.authToken) ?
                <Component {...props} />
                : <Redirect to={`/${id}`} />
        )} />
    );
}

export default PrivateRoute;