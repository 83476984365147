import React, {useCallback, useContext, useEffect, useState} from "react";
import {useAxios} from "../hooks/axiosHook";
import {API_URL, VENDOR} from "../utils";
import {useHistory} from "react-router-dom";
import {useSweetAlertContext} from "./SweetAlertContext";
import {deviceDetect} from "react-device-detect";
import {useJobEventsQueue} from "../hooks/jobEventsHook";
import {useScroll} from "../hooks/scrollHook";
import {useWindowEventHooks} from "../hooks/windowHooks";
import {useVendorsHook} from "../hooks/vendorUtilsHook";

export const ApplicationContext = React.createContext();
export const useApplicationContext = () => useContext(ApplicationContext);
export const ApplicationContextProvider = ({
                                               children
                                           }) => {

    const [vendorQuestions, setVendorQuestions] = useState();
    const [vendorConfig, setVendorConfig] = useState(null);
    const {setSweetAlertConfig, displayError} = useSweetAlertContext();
    const [currentLocation, setCurrentLocation] = useState();
    const history = useHistory();
    const [auth, setAuth] = useState({
        authToken: "",
        customerId: ""
    });

    const [results, setResults] = useState(0);
    const [biggestConcerns, setBiggestConcerns] = useState([]);
    const [allQuestions, setAllQuestions] = useState();
    const [error, setError] = useState(null);

    const {processJob, addJob, flush} = useJobEventsQueue();
    const {dispatchAutoScrollEvent} = useScroll();
    const {post} = useAxios();


    const jobFactory = (event, type, target, value, sessionId, token, payload) => {
        let url = '';
        if(type === 'interaction'){
            url = `${API_URL}/customer/answers/page/events`;
        } else if(type === 'answers'){
            url = `${API_URL}/customer/answers`;
        }

        return {
            url: url,
            token: token ? token : auth.authToken ? auth.authToken : '',
            method: 'POST',
            type: type,
            payload : payload ? payload :  {
                                                event: event,
                                                target: target,
                                                value: value,
                                                sessionId: sessionId ? sessionId : auth.authToken ? auth.authToken.sessionId : '',
                                                occurredOn: new Date()
                                            }
        }
    }
    const saveEvent = (event, id, result, sessionId, token) => {
        processJob(jobFactory(event,'interaction',id, result, sessionId, token));
    }

    useWindowEventHooks(auth, currentLocation, setCurrentLocation, saveEvent);

    const {findVendorQuestionById, canShowItem} = useVendorsHook(vendorQuestions);

    const getVendorConfig = useCallback(() => {
        post(false, `${API_URL}/vendor/config`, auth.authToken, {
            "vendor": VENDOR,
            "customerId": auth.customerId
        }).then(response => {
            const config = JSON.parse(atob(response.data))
            setVendorConfig(config);
            setVendorQuestions(config.vendorQuestions);
        }).catch(error => {
            displayError(error);
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentLocation(window.location.pathname);
    }, []);

    useEffect(() => {
        getVendorConfig();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (vendorConfig) {
            const pathname = window.location.pathname;
            const parts = pathname.split("/");

            parts.shift();
            auth.customerId = parts.shift();

            if (!vendorConfig.isDemo) {
                if (!auth.customerId) {
                    history.push("/error");
                } else {
                    getAuthToken();
                }
            } else {
                getAuthToken();
            }
        }
        // eslint-disable-next-line
    }, [vendorConfig]);

    const getAuthToken = useCallback(() => {
        const deviceType = deviceDetect();

        const displayDialog = setTimeout(() => {
            if (!auth.authToken && error !== null) {
                setSweetAlertConfig(prevState => {
                    return {
                        ...prevState, ...{
                            open: true,
                            title: 'Please wait performing action',
                            type: 'success',
                            message: '',
                            timeout: 0
                        }
                    };
                })
            }
        }, 500)

        const data = {
            "vendor": VENDOR,
            "customerId": auth.customerId,
            "deviceType": deviceType,
            "createdOn": new Date()
        }

        post(false, `${API_URL}/customer/sessions/create`, null, data).then(response => {
            auth.authToken = response.data;

            sessionStorage.setItem("token", JSON.stringify(auth.authToken));

            saveEvent("page_rendered", null, null, auth.authToken.sessionId, auth.authToken);

            setAuth(prevState => {
                return {...prevState, ...auth}
            })

            setSweetAlertConfig(prevState => {
                return {
                    ...prevState, ...{
                        type: '',
                        message: '',
                        open: false
                    }
                };
            })
        }).catch(error => {
            setError(error);
            if (error && error.response && error.response.status === 401) {
                history.push(`/${auth.customerId}/error`)
            } else {
                displayError(error);
            }
        })

        return () => {
            clearTimeout(displayDialog);
        };
        // eslint-disable-next-line
    }, [auth])

    const getAllQuestions = () => {
        const formType = auth.authToken.formType;

        const form = vendorQuestions.formType.find((item) => {
            return item.name === formType ? item : null;
        })

        const aQ = [];

        form.sections.forEach((section) => {
            section.questions.forEach((q) => {
                //if a section has the dontShow property apply that to all the questions in it
                if (section.hasOwnProperty("dontShow")) {
                    if (q.hasOwnProperty("dontShow")) {
                        q.dontShow = q.dontShow.concat(section.dontShow);
                    } else {
                        q.dontShow = section.dontShow;
                    }
                }

                aQ.push(q);
            })
        });

        setAllQuestions(aQ);

        if (vendorConfig && vendorConfig.isDemo) {
            window.history.pushState({page: "Welcome"}, "Safe Loan", `/${auth.customerId}`);
        }
    }

    const calculateScoresForEachDomain = () => {
        let everyDayConcern = 0;
        let rainyDayConcern = 0;
        let oneDayConcern = 0;

        let score = 0;

        allQuestions.forEach((item) => {

            if (canShowItem(item) && score !== -1) {
                const question = findVendorQuestionById(item.id);

                if (question.domain && question.useInWellbeingCalculation) {
                    if (parseInt(question.score) !== 99) {
                        score += question.score;
                        if (question.domain === 'Every Day') {
                            everyDayConcern += question.score;
                        } else if (question.domain === 'Rainy Day') {
                            rainyDayConcern += question.score;
                        } else if (question.domain === 'One Day') {
                            oneDayConcern += question.score;
                        }

                    } else {
                        score = -1;
                    }
                }
            }
        })

        return {
            everyDayConcern: everyDayConcern / 60,
            rainyDayConcern: rainyDayConcern / 20,
            oneDayConcern: oneDayConcern / 20,
            score: score
        }
    }

    const calculateBiggestConcerns = (domainValues) => {
        let concernValues = [];

        const everyDayConcern = domainValues.everyDayConcern * 5;
        const rainyDayConcern = domainValues.rainyDayConcern * 5;
        const oneDayConcern = domainValues.oneDayConcern * 5

        //display the concern with lowest score,
        //if 2 concerns have the same lowest score then display both

        const concerns = [{
            "score": everyDayConcern,
            "name": "every day"
        }, {
            "score": rainyDayConcern,
            "name": "rainy day"
        }, {
            "score": oneDayConcern,
            "name": "one day"
        }]

        //find the minimum value use a reducer
        let minConcern = concerns.reduce((prev, curr) => {
            return prev.score < curr.score ? prev : curr;
        })

        //use filter which will return an array
        let indexes = concerns.filter((itm, index) => itm.score === minConcern.score);

        //we only need 2
        if (indexes.length === 3) {
            return concernValues;
        } else {
            indexes.forEach((itm) => {
                concernValues.push(itm.name)
            })

            return concernValues;
        }
    }

    return (
        <ApplicationContext.Provider
            value={{
                results,
                setResults,
                biggestConcerns,
                setBiggestConcerns,
                vendorConfig,
                vendorQuestions,
                getVendorConfig,
                auth,
                setVendorQuestions,
                canShowItem,
                findVendorQuestionById,
                allQuestions,
                setCurrentLocation,
                saveEvent,
                calculateScoresForEachDomain,
                calculateBiggestConcerns,
                displayError,
                processJob, addJob, flush, dispatchAutoScrollEvent,
                getAllQuestions,
                jobFactory
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );
}


