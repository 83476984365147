import {Container} from "react-bootstrap";
import React from "react";
import {useApplicationContext} from "../context/AppContext";
import {getIconForClass} from "../utils";
import ResultsBackButton from "./ResultsBackButton";


const NoResultsContainer = () => {
    const {
        vendorConfig,
        saveEvent
    } = useApplicationContext();

    const NoResultsCtaRenderer = () => {
        const ctas = vendorConfig.callToAction.noResultCta;

        return (
            <ul className="list-group">
                {ctas.map((cta, index) => {
                    return (
                        <a key={index} href={cta.url} target={"_blank"} rel="noreferrer" className="list-group-item list-group-item-action" onClick={() => {
                            saveEvent('click',cta.url, cta.label);;
                        }}>{getIconForClass(cta.iconClass)}{cta.label}</a>
                    )
                })
                }
            </ul>
        )
    }

    return (
        <Container>
            <h1>Thank You!</h1>
            <p>
                Thank you for taking the time to answer our questions. It seems that your finances are an area of uncertainty for you.
                Given your answers, we aren't able to give you a score, but what we can do is give you access to some resources to help you think about your financial circumstances.
                When you're ready, you can use the link sent to you to redo the survey and get your score if you’d like to.
            </p>
            <ResultsBackButton />

            <h2>Helpful resources</h2>
            <p>
                As a first step you might look at the following resources.
            </p>
            <NoResultsCtaRenderer />
        </Container>
    )
};

export default NoResultsContainer;
