import {ArrowDown, ArrowUp} from "react-bootstrap-icons";
import React from "react";
import {useApplicationContext} from "../context/AppContext";

const NextPrev = ({formQuestion, vendorQuestion, getElementToScrollTo}) => {
    const {
        allQuestions,
        saveEvent
    } = useApplicationContext();

    const canShowPrev = () => {
        const index = allQuestions.findIndex((item) => {
            return item.id === formQuestion.id;
        });

        return index !== 0;
    }

    const canShowNext = () => {
        if(Array.isArray(vendorQuestion.score)){
            return vendorQuestion.score.length > 0;
        }else {
            return vendorQuestion.score !== "" && vendorQuestion.score !== "-1";
        }
    }

    return (
        <div>
            {canShowPrev() &&
            <button type={"button"} className="in-page-nav" onClick={() => {
                getElementToScrollTo(vendorQuestion, 'previous');
                saveEvent('previous', vendorQuestion.id);
            }}>
                <ArrowUp className="mr-1"/>Previous</button>
            }
            {canShowNext() &&
            <button type={"button"} className="in-page-nav in-page-nav-next" onClick={() => {
                getElementToScrollTo(vendorQuestion, 'next');
                saveEvent('next', vendorQuestion.id);
            }}>
                <ArrowDown className="mr-1"/>Next</button>
            }
        </div>

    )
}

export default NextPrev;