import {Modal} from "react-bootstrap";
import Mailto from "react-protected-mailto";
import React from "react";
import {ArrowLeft} from "react-bootstrap-icons";

const SafeLoanFAQ = ({setFaqOpen, faqOpen}) => {

    return (
        <Modal
            show={faqOpen}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={() => setFaqOpen(false)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    MoneyPulse FAQ
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>What’s the purpose of the survey?</h4>
                <p>
                    The survey aims to help you to better understand your financial wellbeing. We’ll be asking you
                    questions which will help assess, in a way that is valuable to you:
                </p>
                <ul>
                    <li>
                        any hidden or pre-existing financial stress, and
                    </li>
                    <li>
                        your financial habits.
                    </li>
                </ul>
                <h4>What will happen to information about me?</h4>
                <p>
                    The survey is completed anonymously. That means that no information that identifies any individual is collected or shared.
                    Your result has a unique code which is what we use to do our analysis work. Your lender doesn’t know your survey answers,
                    and Verifier and our academic partners never know who you are. For more see our <a href={"https://www.verifier.me/privacy"} target={"_blank"} rel="noreferrer">Privacy Policy </a> or contact us at &nbsp;
                    <Mailto email='moneypulse@verifier.me'/>
                </p>
                <h4>What is financial wellbeing?</h4>

                <p>It’s how your choices with money affect your life today and in the future.</p>

                <h4>What to expect at survey completion?</h4>

                <p>
                    If you answer all the questions, you’ll receive a score that summarises your financial wellbeing.
                    Based on your responses, you’ll have access to resources that aim to help you if needed and boost your financial literacy.
                </p>

                <h4>What is the financial wellbeing index score?</h4>

                <p>
                    The financial wellbeing index score was developed by our academic partner from <a href={"https://fbe.unimelb.edu.au/research/streams/health-and-wellbeing/measuring-financial-wellbeing"} target={"_blank"} rel={"noreferrer"}>
                    the Melbourne Institute: Applied Economic & Social Research, the University of Melbourne.</a>
                    The score is derived based on survey responses to the questions, which are then scaled to a score between 0 to 100.
                </p>

                <h4>What will I be asked to do?</h4>

                <p>If you want to participate, you need only answer the questions in the survey. This should only take approximately under 5 minutes of your time.
                    When you complete the survey, you can access the suggested resources and review them in your own time.
                </p>

                <h4>What are the benefits?</h4>

                <p>
                    In addition to helping you to get a better understanding of your financial wellbeing,
                    aggregated anonymous survey responses will help us deliver insights about how your lender’s services
                    impact a group of people like you. Ultimately, these insights will help your lender to deliver better
                    borrowing outcomes for all customers.
                </p>

                <h4>What are the possible risks?</h4>

                <p>
                    As the survey is conducted anonymously and our systems are secured according to ISO 27001: 2013 Information Security Standards, there are minimal risks to participants.
                </p>

                <h4>Where can I get further information?</h4>
                <p>
                    If you would like more information about the MoneyPulse survey, please contact the facilitator of Verifier’s MoneyPulse Program at &nbsp;
                    <Mailto email='moneypulse@verifier.me'/>
                </p>

            </Modal.Body>
            <Modal.Footer>
                <button data-testid="goBack" type={"button"} className={"in-page-nav mb-5"}
                        onClick={() => {
                            setFaqOpen(false);
                        }}><ArrowLeft
                    className="mr-1"/><strong>Go back to survey</strong>
                </button>
            </Modal.Footer>
        </Modal>
    )
};

export default SafeLoanFAQ;
