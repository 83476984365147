import React, {useEffect} from 'react';
import {useApplicationContext} from "../context/AppContext";
import ResultsContainer from "./ResultsContainer";
import NoResultsContainer from "./NoResultsContainer";

const Results = () => {
    const {
        results,
        setCurrentLocation
    } = useApplicationContext();


    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentLocation(window.location.pathname);
        // eslint-disable-next-line
    }, []);


    return (
        <div className={"results"} id={"results"}>
            {results && results >= 0 ? <ResultsContainer/> : <NoResultsContainer/>}
        </div>
    )
}

export default Results;