import {Form} from "react-bootstrap";
import React from "react";
import {useApplicationContext} from "../context/AppContext";

const RadioAnswer = ({vendorQuestion, answer, getElementToScrollTo, getSelectedAnswer}) => {
    const {
        vendorQuestions,
        setVendorQuestions,
        saveEvent
    } = useApplicationContext();


    return (
        <Form.Check
            checked={getSelectedAnswer(vendorQuestion.id, answer)}
            label={answer.label}
            name={vendorQuestion.name}
            type={"radio"} id={answer.id}
            data-testid={answer.id}
            onChange={() => {

                vendorQuestion.score = answer.value;
                vendorQuestion.freeFormTextFieldValue = "";

                setVendorQuestions(prevState => {
                    return {...prevState, ...vendorQuestions}
                })

                saveEvent('click', answer.id, String(answer.value));

                //answer.type = "radio";

                if (!answer.freeFormTextFieldRequired) {
                    getElementToScrollTo(vendorQuestion, 'next');
                }

                //updateCustomerAnswers(vendorQuestion, answer);
            }}
        />
    )
};

export default RadioAnswer;