import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {useSweetAlertContext} from "../context/SweetAlertContext";
import {Spinner} from "react-bootstrap";


const ProgressDialog = () => {

    const {sweetAlertConfig,setSweetAlertConfig} = useSweetAlertContext();

    const onConfirm = () => {
        setSweetAlertConfig(prevState => {
            const currentState = prevState;
            currentState.open = false;
            return {...prevState,...currentState}
        })
    }

    return (sweetAlertConfig && sweetAlertConfig.open && sweetAlertConfig.type === 'success' && <SweetAlert
        info
        title={sweetAlertConfig.title}
        onConfirm={() => onConfirm()}
        onCancel={onConfirm}
        timeout={sweetAlertConfig.timeout}
        openAnim={{ name: 'showSweetAlert', duration: 1000 }}
        closeAnim={{
            popup: 'swal2-hide',
            backdrop: 'swal2-backdrop-hide',
            icon: 'swal2-icon-hide'
        }}
        closeOnClickOutside={false}
        allowEscape={false}
        showCloseButton={false}
        showConfirm={false}

        dependencies={[]}
    >
        {() => (
            <form>
                <div className={"text-center"}><Spinner animation="grow" variant="primary" /></div>
                {sweetAlertConfig.message}
            </form>
        )}
    </SweetAlert>)
}

export default ProgressDialog;