import {useRef} from "react";
import {useAxios} from "./axiosHook";

export const useJobEventsQueue = () => {

    const {post, performApiCall} = useAxios();

    const jobEvents = useRef([]);

    const addJob = (job) => {
        jobEvents.current.push(job);
    }

    const process = (job, counter) => {
        if(counter > 0) {
            post(false, job.url, job.token, job.payload).catch(() => {
                process(job, --counter);
            })
        } else {
            addJob(job);
        }
    }

    const processJob = (job) => {
        let attemptsRemaining = 3;

        //so first try and flush the queue before processing this job
        //basically re-use the existing flush function the success handler in this case is to call process(job)
        //the error handler is the addJob -> it stands to reason that if the call failed to process the existing jobs on the queue
        //then it will fail for this current job, so may as well just add it to the queue rather then attempt it.
        flush(() => process(job, attemptsRemaining), () => addJob(job))
    }

    const flush = (successHandler, errorHandler) => {
        if(jobEvents.current.length > 0){
            const job = jobEvents.current.slice(0,1).pop();

            performApiCall(false, job.method, job.url, job.token, job.payload).then(() => {
                jobEvents.current.shift();
                flush(successHandler, errorHandler);
            }).catch((error) => {
                errorHandler(error,jobEvents.current);
            })
        } else {
           successHandler();
        }
    }


    return {processJob, addJob, flush}
}