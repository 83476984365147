import './App.scss';
import {Container} from "react-bootstrap";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Welcome from "./welcome/Welcome";
import Results from "./results/Results";
import ErrorPage from "./Error/ErrorPage";
import React from "react";
import PrivateRoute from "./PrivateRoute";
import {ApplicationContextProvider} from "./context/AppContext";
import {SweetAlertContextProvider} from "./context/SweetAlertContext";
import ErrorDialog from "./Dialog/ErrorDialog";
import ProgressDialog from "./Dialog/ProgressDialog";

function Copyright() {
    return (
        <div className={"text-center"}>
            <small>{'Copyright '} &copy;&nbsp;
                <a target="_blank" rel="noreferrer" href="http://www.verifier.me">Verifier </a>
                {new Date().getFullYear()}{'.'}
            </small>
        </div>
    );
}

function Header() {
    return (
        <header id="header" className="verifier-header" tabIndex="-1">
            <Container fluid="sm" className="verifier-flex-column">
                <div className="verifier-header-logo"><span className="sr-only">MoneyPulse logo</span></div>
                <nav className="verifier-header-nav">
                    <ul className="nav nav-tabs verifier-header-nav-list">
                    </ul>
                </nav>
            </Container>
        </header>
    );
}


function App() {
    return (
        <div className="App">
            <Header/>

            <BrowserRouter>
                <SweetAlertContextProvider>
                    <ApplicationContextProvider>
                        <ErrorDialog/>
                        <ProgressDialog/>
                        <Switch>
                            <Route path='/' component={Welcome} exact/>
                            <Route path='/error'  render={props => (
                                <ErrorPage {...props} message={"The ID supplied in the URL is invalid."}/>)
                            } exact/>

                            <Route path='/:id/error' render={props => (
                                <ErrorPage {...props} message={"The ID supplied in the URL is invalid."}/>)
                            } exact/>

                            <Route path='/:id' component={Welcome} exact/>
                            <PrivateRoute component={Results} path='/:id/results' exact/>
                        </Switch>
                    </ApplicationContextProvider>
                </SweetAlertContextProvider>
            </BrowserRouter>

            <footer>
                <Copyright/>
            </footer>

        </div>
    );
}

export default App;
