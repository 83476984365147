import React, {useEffect} from 'react';
import {Container} from "react-bootstrap";

const ErrorPage = ({message}) => {

    useEffect(() => {
        window.scrollTo(0, 0);

    },[])


    return (
        <Container>
            <h2>Invalid URL</h2>
            <p>Please check the link you have used. Make sure it’s the same as the one you received in the email from your lender.</p>

            <p>Hint: You can copy the link from your email and paste it in your browser to avoid and issues.</p>
        </Container>

    )
}

export default ErrorPage;