import React, {useState} from "react";
import {Col, Form} from "react-bootstrap";
import {useApplicationContext} from "../context/AppContext";

const OtherTextFieldAnswer = ({vendorQuestion, answer, getElementToScrollTo}) => {
    const {
        vendorQuestions,
        setVendorQuestions,
        saveEvent
    } = useApplicationContext();

    const [value, setValue] = useState(vendorQuestion.freeFormTextFieldValue)

    return (
        <Form.Group>
            <Col>
                <Form.Control as="textarea" rows={3} value={value}  style={{marginLeft:"10px"}} className={!value ? "other-error" : ""}
                              onChange={(e) => {
                                  setValue(e.target.value)
                              }}
                              onBlur={() => {
                                  vendorQuestion.freeFormTextFieldValue = value;

                                  saveEvent('other', answer.id, value);

                                  setVendorQuestions(prevState => {
                                      return {...prevState, ...vendorQuestions}
                                  })

                                  getElementToScrollTo(vendorQuestion, 'next');
                              }}
                />
                {!value && <Form.Text style={{color: "red", marginLeft :"10px"}}>
                    Please specify.
                </Form.Text>
                }
            </Col>

        </Form.Group>

    )
}

export default OtherTextFieldAnswer;