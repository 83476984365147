
const scrollIntoView = require("scroll-into-view");

export const scroll = (e) => {
    const section = document.querySelector(e.detail.id);

    const timeVal = 2500;

    scrollIntoView(section, {
        time: timeVal,
        align: {
            top: 0.0,
            left: 0.5
        },
        ease: function (value) {
            return 1 - Math.pow(1 - value, value / 5);
        }
    });
}

window.addEventListener('autoScroll', scroll, true);

export const useScroll = () => {

    window.onmousewheel = () => {
        window.removeEventListener('autoScroll', scroll, true);
    }

    const dispatchAutoScrollEvent = (id) => {
        const section = document.querySelector(id);
        if(section) {
            window.addEventListener('autoScroll', scroll, true);

            const event = new CustomEvent('autoScroll', {
                detail: {
                    id: id,
                    autoScroll: true,
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            window.dispatchEvent(event);
        } else {
            setTimeout(() => {
                dispatchAutoScrollEvent(id);
            },500);
        }
    }

    return {dispatchAutoScrollEvent}
}