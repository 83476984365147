import React, {useState} from "react";
import {getIconForClass} from "../utils";
import {Fade} from "react-bootstrap";
import {useApplicationContext} from "../context/AppContext";

const ResultsCTARenderer = () => {

    const {results,
        biggestConcerns,
        vendorConfig, saveEvent} = useApplicationContext();

    const [displayHiddenResources, setDisplayHiddenResources] = useState(false);
    //we need 2 cta lists one based on score one based on domain

    const ctas = vendorConfig.callToAction.ctas;

    //we need to filter based on score which is stored in scores
    let cta = ctas.scores.find((cta) => {
        return results <= cta.score;
    });

    let concerns = JSON.parse(JSON.stringify(biggestConcerns));
    let additionalResources = [];
    let hiddenResources = [];
    //if we have more then 1 domain then we need to only show partial
    if(concerns.length === 1){
        const concern = concerns.pop().toLowerCase();
        if(ctas.domains) {
            const cta = ctas.domains.find((obj) => {
                return obj.domain.toLowerCase() === concern
            })

            if(cta) {
                cta.resources.forEach((resource) => resource.domain = cta.domain);
                additionalResources = cta ? cta.resources : [];
            }
        }
    } else {
        //we either have 2 or 3 areas of concern, this means that we want to distinguish between links that should be displayed and those that should be hidden
        //if we have 0 concerns it means that we have all of them
        if(concerns.length === 0){
            concerns = ["every day", "rainy day", "one day"]
        }

        concerns.forEach((concern) => {
            if(ctas.domains) {
                let cta = ctas.domains.find((obj) => {
                    return obj.domain.toLowerCase() === concern.toLowerCase();
                })

                if (cta) {
                    const resourcesToShow = cta.resources.filter((resource) => resource.showWhenMoreThenOneDomain === true);
                    resourcesToShow.forEach((resource) => resource.domain = cta.domain);
                    additionalResources.push(...resourcesToShow);

                    const resourcesToHide = cta.resources.filter((resource) => resource.showWhenMoreThenOneDomain === false);
                    resourcesToHide.forEach((resource) => resource.domain = cta.domain);
                    hiddenResources.push(...resourcesToHide);
                }
            }
        })
    }

    return (
        <>
            <h3>Helpful resources</h3>
            <p>
                Try the following resources if you'd like to follow up on what you've learned today.
            </p>
            <ul className="list-group mb-2">
                { cta && cta.resources.map((cta, index) => {
                    return (
                        <a key={index} href={cta.url} target={"_blank"} rel="noreferrer" className="list-group-item list-group-item-action" onClick={() => {
                            saveEvent('click',cta.url, cta.label);
                        }}>{getIconForClass(cta.iconClass)}{cta.label}</a>
                    )
                })
                }
            </ul>
            {additionalResources.length > 1 &&
            <>
                <p>
                    Also, these additional resources address your biggest areas of concern:
                </p>
                <ul className="list-group">
                    {cta && additionalResources.map((cta, index) => {
                        return (
                            <a key={index} href={cta.url} target={"_blank"} rel="noreferrer"
                               className="list-group-item list-group-item-action" onClick={() => {
                                saveEvent('click', cta.url, cta.label);
                            }}>{getIconForClass(cta.iconClass)}{cta.domain} : {cta.label}</a>
                        )
                    })
                    }
                </ul>
            </>
            }

            {hiddenResources.length > 1 &&
            <>
                <p>
                    <button type={"button"}
                            data-testid={"additional-resources-link"}
                            className={"additional-resources-link mt-2"}
                            onClick={() => setDisplayHiddenResources(!displayHiddenResources)}
                            aria-controls="hidden-resources"
                            aria-expanded={displayHiddenResources}
                    >
                        If you are interested in more resources, click here...
                    </button>
                </p>
                <Fade in={displayHiddenResources}>
                    <div id="hidden-resources" data-testid={"hidden-resources"}>
                        {displayHiddenResources && <ul className="list-group">
                            {cta && hiddenResources.map((cta, index) => {
                                return (
                                    <a key={index} href={cta.url} target={"_blank"} rel="noreferrer"
                                       className="list-group-item list-group-item-action" onClick={() => {
                                        saveEvent('click',cta.url, cta.label);
                                    }}>{getIconForClass(cta.iconClass)}{cta.domain} : {cta.label}</a>
                                )
                            })
                            }
                        </ul>
                        }
                    </div>
                </Fade>
            </>
            }
        </>
    )
}

export default ResultsCTARenderer;