import axios from "axios";
import {loadProgressBar} from "axios-progress-bar";

export const useAxios = () => {

    const get = async (cancelRequest, url, token) => {
       return performApiCall(cancelRequest, 'GET', url, token);
    }

    const post = async (cancelRequest, url, token, data) => {
        return performApiCall(cancelRequest, 'POST', url, token, data);
    }

    const put = async (cancelRequest,  url, token, data) => {
        return performApiCall(cancelRequest, 'PUT', url, token, data);
    }

    const performApiCall = async (cancelRequest, method, url, token, data) => {
        const config = {
            method: method,
            url: url
        }

        if(token){
            config.headers = {
                Authorization: `Bearer ${token ? token.token : ''}`
            }
        }

        if(data){
            config.data = data;
        }

        loadProgressBar(config);
        return axios(config).then(response => {
            if (cancelRequest) {
                return;
            }

            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error)
        })
    }

    return {get, post, put, performApiCall};
};