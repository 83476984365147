import React, {useCallback, useContext, useState} from "react";

export const SweetAlertContext = React.createContext();
export const useSweetAlertContext = () => useContext(SweetAlertContext);
export const SweetAlertContextProvider = ({
                                          children
                                      }) => {

    const [sweetAlertConfig,setSweetAlertConfig] = useState({
        open:false,
        title:'',
        type:'',
        message:'',
        timeout:0,
        closeFunction:null
    })

    const displayError = useCallback((error) => {
        let closeFunction = null;
        if (error && error.response && error.response.status === 401) {
            sessionStorage.removeItem("token");
            closeFunction = () => {
                window.scrollTo(0, 0);
            }
        }

        setSweetAlertConfig(prevState => {
            return {
                ...prevState, ...{
                    open: true,
                    title: error && error.response && error.response.statusText ? error.response.statusText : "",
                    type: 'error',
                    message: error && error.response && error.response.data && error.response.data.hasOwnProperty("msg") ? error.response.data.msg : 'We apologise but an unexpected error has occurred.',
                    timeout: 0,
                    closeFunction: closeFunction
                }
            };
        })
        // eslint-disable-next-line
    }, [])

    return (
        <SweetAlertContext.Provider
            value={{
                sweetAlertConfig,
                setSweetAlertConfig,
                displayError
            }}
        >
            {children}
        </SweetAlertContext.Provider>
    );
};