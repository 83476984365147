import {useHistory} from "react-router-dom";
import {useCallback} from "react";

export const useWindowEventHooks = (auth, currentLocation, setCurrentLocation, saveEvent) => {
    const history = useHistory();

    const shouldDisplayExitPopup = useCallback(() => {
        if (currentLocation && (currentLocation.indexOf('results') === -1)) {
            return true;
        }
    }, [currentLocation])

    const confirmExit = () => {
        if (shouldDisplayExitPopup()) {
            return "Are you sure you want to leave site?";
        }
    }

    //todo discuss the issue of reloading the page not clearing the token because there is no way to get the result of the users selection
    //one approach, live with it, second don't store the token in the session, every page refresh will be considered as a new attempt
    window.onbeforeunload = function() { return confirmExit(); }

    window.onpopstate = (event) => {
        window.scrollTo(0, 0);
        if (currentLocation && (currentLocation.indexOf('results') !== -1 && event.currentTarget.location.pathname.indexOf('results') === -1)) {
            setCurrentLocation(event.currentTarget.location.pathname);
            saveEvent('back');
        }

        const baseNewLocation = event.currentTarget.location.pathname.split("/")[1];
        const baseCurrentLocation = currentLocation ? currentLocation.split("/")[1] : "";

        if (baseNewLocation !== baseCurrentLocation && shouldDisplayExitPopup()) {
            // eslint-disable-next-line no-restricted-globals
            const result = confirm("Leave site?")

            if (!result) {
                history.push(currentLocation)
            } else {
                sessionStorage.removeItem("token");
            }
        }
    }

    const handVisibilityChangeEvent = useCallback(() => {
            if(auth && auth.authToken) {
                saveEvent(`page_${document.visibilityState}`);
            }
            // eslint-disable-next-line
        }, [auth]
    );

    document.onvisibilitychange = () => {
        handVisibilityChangeEvent();
    }
}