import {Form} from "react-bootstrap";
import React from "react";
import {useApplicationContext} from "../context/AppContext";

const CheckBoxAnswer = ({vendorQuestion, answer, getSelectedAnswer}) => {
    const {
        vendorQuestions,
        setVendorQuestions,
        saveEvent
    } = useApplicationContext();

    const updateCheckBoxAnswer = () => {
        const vendorScore = vendorQuestion.score.find((sc) => {
            return sc.id === answer.id;
        })

        if (!vendorScore) {
            vendorQuestion.score.push({
                id: answer.id,
                label: answer.label,
                score: answer.value,
                freeFormTextFieldRequired: !!answer.freeFormTextFieldRequired
            })
        } else {
            //find index and splice
            const indx = vendorQuestion.score.findIndex((sc) => {
                return sc.id === answer.id;
            })

            if (indx !== -1) {
                vendorQuestion.score.splice(indx, 1);
                if (vendorScore.freeFormTextFieldRequired) {
                    vendorQuestion.freeFormTextFieldValue = "";
                }
            }
        }
    }

    return (
        <Form.Check
            checked={getSelectedAnswer(vendorQuestion.id, answer)}
            label={answer.label}
            name={answer.id}
            type={answer.type} id={answer.id}
            data-testid={answer.id}
            onChange={(e) => {

                if(e.target.checked){
                    saveEvent(`selected`, answer.id, answer.label);
                }else{
                    saveEvent(`de-selected `, answer.id, answer.label);
                }

                updateCheckBoxAnswer();

                setVendorQuestions(prevState => {
                    return {...prevState, ...vendorQuestions}
                })
            }}
        />
    )
};

export default CheckBoxAnswer;