
export const useVendorsHook = (vendorQuestions) => {

    const findVendorQuestionById = (id) => {
        let vendorQuestion = null;

        vendorQuestions.sections.forEach((section) => {
            const iq = section.questions.find((iq) => {
                return iq.id === id ? iq : null;
            })

            if (iq) {
                vendorQuestion = iq;
            }
        })

        return vendorQuestion;
    }

    const canShowItem = (item) => {
        let show = true;

        if (typeof item.dontShow === 'object' && item.dontShow !== null && vendorQuestions) {

            item.dontShow.forEach((fn) => {
                //only calculate if show is true
                if (show) {
                    const exp = fn.expr;

                    //L1 equals 0 or L1 equals 99
                    let expressions = [];

                    let op = "";
                    if (exp.indexOf(" or ") !== -1) {
                        expressions = exp.split(" or ");
                        op = "or";
                    }

                    if (exp.indexOf(" and ") !== -1) {
                        expressions = exp.split(" and ");
                        op = "and";
                    }

                    const showResults = [];
                    expressions.forEach((exp) => {

                        const parts = exp.split(" ");

                        if (parts.length === 3) {
                            const left = parts.shift();
                            const op = parts.shift();
                            const right = parts.shift();

                            const q = findVendorQuestionById(left);

                            if (q && q.score !== "") {
                                show = !performOperation(op, q.score, parseInt(right));
                                showResults.push(show);
                            }
                        }
                    });

                    if (showResults.length > 1) {
                        if (op === "or") {
                            const exp1 = showResults.pop();
                            const exp2 = showResults.pop();
                            show = exp1 && exp2
                        } else if (op === "and") {
                            const exp1 = showResults.pop();
                            const exp2 = showResults.pop();
                            show = exp1 || exp2;
                        }
                    }
                }
            })
        }

        return show;
    }

    const performOperation = (operator, x, y) => {
        let f = undefined;
        switch (operator) {
            case '===' :
                f = (x, y) => {
                    return x === y;
                };
                break;

            case 'equals' :
                f = (x, y) => {
                    return x === y;
                };
                break;

            case '!==' :
                f = (x, y) => {
                    return x !== y;
                };
                break;

            default:
                break;
        }

        return f ? f(x, y) : false;
    }

    return {findVendorQuestionById, canShowItem}
}