import React, {useState} from "react";
import {LENDER} from "../utils";
import {Col, Form, Row} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import {useApplicationContext} from "../context/AppContext";

const RangeSliderAnswer = ({vendorQuestion}) => {
    const {
        vendorQuestions,
        setVendorQuestions,
        saveEvent
    } = useApplicationContext();

    const [value, setValue] = useState(vendorQuestion.score ? vendorQuestion.score : vendorQuestion.options.min)

    return (
        <div>
            <p>Drag the slider to select a number between 0 and 10 that represents the likelihood of you recommending {LENDER}, where "0" is "not at all likely" and "10" is "extremely likely", then click Next.</p>
            <Form.Group as={Row} className="slider-container">
                <Form.Label column sm="3" className="text-right d-none d-sm-block">
                    Not at all likely
                </Form.Label>
                <Col sm="6">
                    {value && <RangeSlider
                        value={value}
                        min={vendorQuestion.options.min}
                        max={vendorQuestion.options.max}
                        step={vendorQuestion.options.step}
                        size={"lg"}
                        onChange={e => {
                            setValue(e.target.value)
                        }}
                        onAfterChange={(e) => {

                            vendorQuestion.score = e.target.value;

                            /*updateCustomerAnswers(vendorQuestion, {
                                value: e.target.value,
                                id: vendorQuestion.id,
                                type: 'range'
                            })*/

                            saveEvent(`range`, vendorQuestion.id, String(e.target.value));

                            setVendorQuestions(prevState => {
                                return {...prevState, ...vendorQuestions}
                            })

                        }}

                        tooltip={"on"}
                        tooltipLabel={(e) => {
                            const toolTipLabel = vendorQuestion.options.toolTipLabels.find((itm) => {
                                return itm.value === parseInt(e);
                            })

                            return toolTipLabel ? toolTipLabel.label : e;
                        }}
                    />
                    }
                </Col>
                <Form.Label column sm="3" className="d-none d-sm-block">
                    Extremely likely
                </Form.Label>
            </Form.Group>
        </div>
    )
}

export default RangeSliderAnswer;