import RangeSliderAnswer from "./RangeSliderAnswer";
import CheckBoxAnswer from "./CheckBoxAnswer";
import RadioAnswer from "./RadioAnswer";
import OtherTextFieldAnswer from "./OtherTextFieldAnswer";
import NextPrev from "./NextPrev";
import React from "react";
import {useApplicationContext} from "../context/AppContext";


const Question = ({section, index, vendorQuestion, formQuestion}) => {
    const {
        vendorQuestions,
        canShowItem,
        findVendorQuestionById,
        allQuestions,
        dispatchAutoScrollEvent
    } = useApplicationContext();

    const getSectionTitle = (section) => {
        const item = vendorQuestions.sections.find((item) => {
            return item.section === section ? item : null
        })

        return (
            <h1 className="section-heading">
                {item.title}
            </h1>
        )
    };

    const getSelectedAnswer = (id, answer) => {
        const question = findVendorQuestionById(id);
        if (answer.type && answer.type === 'checkbox') {
            const selectedAnswer = question.score.find((itm) => {
                return itm.id === answer.id ? itm : null;
            })

            if (selectedAnswer) {
                return selectedAnswer.score === answer.value;
            }else{
                return false;
            }
        } else {
            return question.score === answer.value;
        }
    }

    const getTitle = (vendorQuestion) => {
        if (vendorQuestion.question) {
            return (
                <div className="mb-4">
                    <h2 className="mt-0">
                        {vendorQuestion.leadQuestion}
                    </h2>
                    <p className="lead question-statement">
                        {vendorQuestion.question}
                    </p>
                </div>
            )
        } else {
            return <h2 className="mt-0 mb-4">
                {vendorQuestion.leadQuestion}
            </h2>
        }
    }

    const findItemToScrollTo = (nQuestions) => {
        if (nQuestions) {
            const el = nQuestions.find((item) => {
                if (!item.dontShow) {
                    return item;
                } else {
                    const show = canShowItem(item);
                    return show ? item : null
                }
            })

            if (el) {
                return el;
            } else {
                return findItemToScrollTo(nQuestions.shift());
            }
        }
    }

    const getElementToScrollTo = (vendorQuestion, direction) => {

        const elIndex = allQuestions.findIndex((item) => {
            return item.id === vendorQuestion.id;
        })

        //we want to reverse the order of the array of questions if we are going backwards
        const nQuestions = direction === 'next' ? allQuestions.slice(elIndex + 1) : allQuestions.slice(0, elIndex).reverse();

        //find the first item that can be displayed
        const el = findItemToScrollTo(nQuestions);

        if (el) {
            dispatchAutoScrollEvent(`#${el.id}`);
        } else {
            //there is no question left to scroll to so scroll to resultsBtn
            if (direction === 'next') {
                dispatchAutoScrollEvent(`#resultsBtn`);
            }
        }
    }

    const displayTextField = (vendorQuestion, answer) => {
        if (Array.isArray(vendorQuestion.score)) {
            return vendorQuestion.score.find((itm) => {
                return itm.freeFormTextFieldRequired ? itm : null
            })
        } else {
            return vendorQuestion.score === answer.value;
        }
    }


    return (
        <>
            <div data-testid={vendorQuestion.id}>
                {index === 0 && getSectionTitle(section.section)}
                {getTitle(vendorQuestion)}
                <div className="well">
                    <span className="sr-only">Note for screenreaders: The following radio buttons will immediately move you to the next question if you activate them. We recommend using your tab or right/left arrow keys to navigate so as not to cause radio button activation until you've chosen your option.</span>
                    {vendorQuestion.type && vendorQuestion.type === 'range' ?
                        <div className={"mb-2"}>
                            <RangeSliderAnswer vendorQuestion={vendorQuestion}/>
                        </div>
                        : vendorQuestion.answers && vendorQuestion.answers.map((answer) => {
                        return answer.showInForm &&
                            <div key={answer.id}>
                                {answer.type && answer.type === 'checkbox' ?
                                    <CheckBoxAnswer vendorQuestion={vendorQuestion} answer={answer}
                                                    getSelectedAnswer={getSelectedAnswer}/> :
                                    <RadioAnswer vendorQuestion={vendorQuestion} answer={answer}
                                                 getElementToScrollTo={getElementToScrollTo}
                                                 getSelectedAnswer={getSelectedAnswer}/>}

                                {(answer.freeFormTextFieldRequired && displayTextField(vendorQuestion, answer)) &&
                                <OtherTextFieldAnswer vendorQuestion={vendorQuestion} answer={answer}
                                                      getElementToScrollTo={getElementToScrollTo}/>
                                }
                            </div>
                    })
                    }
                </div>
            </div>
            <NextPrev vendorQuestion={vendorQuestion} formQuestion={formQuestion} getElementToScrollTo={getElementToScrollTo}/>
        </>
    )
}

export default Question;