import React, {useState} from 'react';
import {Button, Container} from "react-bootstrap";
import {useApplicationContext} from "../context/AppContext";
import Questionnaire from "../questionnaire/Questionnaire";
import SafeLoanFAQ from "../Dialog/SafeLoanFAQ";
import Mailto from "react-protected-mailto";

const Welcome = () => {
    const {dispatchAutoScrollEvent, saveEvent, getAllQuestions, allQuestions} = useApplicationContext();
    const [faqOpen, setFaqOpen] = useState(false);

    const next = () => {
        dispatchAutoScrollEvent('.question');
        saveEvent('start');
        if(!allQuestions) {
            getAllQuestions();
        }
    }

    return (
        <div id="welcome" className={"welcome"}>
            <Container className={"welcome-section"}>
                {faqOpen && <SafeLoanFAQ setFaqOpen={setFaqOpen} faqOpen={faqOpen}/>}
                <div className={"pb-2"}>
                    <h1 data-testid={"welcome"}>Welcome to MoneyPulse</h1>
                    <p>
                        Thank you for participating in the MoneyPulse survey.
                    </p>
                    <p>
                        In completing this survey, you may get instant feedback on your financial wellbeing - which is
                        all about helping you understand how your choices with money affect you,
                        now and in the future. You'll also get access to resources to support you.
                    </p>
                    <p>
                        And, you’re paying it forward too! Your anonymous responses will be combined with those of other consumers and used
                        by Verifier and its academic partners to deliver insights to organisations who want
                        to improve the experience for all customers. That’s people power!
                    </p>
                    <h2>Things you need to know - at a glance</h2>
                    <ul>
                        <li>
                            We don't collect, use, share, or store any personal information that identifies you.
                        </li>
                        <li>
                            You'll get a financial wellbeing index score if you answer the survey questions.
                        </li>
                        <li>
                            This index score was developed by our academic partner from the Melbourne Institute: Applied
                            Economic & Social Research, the University of Melbourne.
                        </li>
                        <li>
                            Answering the survey questions only takes a few minutes.
                        </li>
                    </ul>
                    <p>
                        For more information, visit the <Button style={{margin: 0, padding: 0}} variant="link"
                                                                onClick={() => setFaqOpen(true)}>MoneyPulse Survey FAQ
                        page</Button>. It will provide you with further information about the survey, so that you can
                        decide if you'd like to participate.
                        If you don't wish to participate, you don't have to. If you begin the survey, you can choose to
                        stop at any time and you can re-use
                        the link to redo the survey if you want to update your answers.
                    </p>
                    <p>
                        If you would like more information about the MoneyPulse survey, please contact the facilitator of
                        Verifier's MoneyPulse Program at <Mailto email='moneypulse@verifier.me'/>
                    </p>
                </div>
                <div className={"well"}>
                    <Button data-testid={"start"} variant="primary" size="lg" onClick={() => next()}>Let's go</Button>
                </div>
            </Container>
            {allQuestions && <Questionnaire/>}
        </div>
    )
}

export default Welcome;