import {
    ArrowClockwise,
    ArrowsCollapse,
    Cash,
    CloudRainHeavy,
    CreditCard,
    Film,
    GraphUp,
    ShieldCheck,
    Telephone,
    Wallet,
    Wallet2,
    PatchQuestion,
    CashStack
} from "react-bootstrap-icons";
import React from "react";

export const API_URL = (window && window.safeLoanConfig && window.safeLoanConfig.api_url) ? window.safeLoanConfig.api_url : process.env.REACT_APP_API_URL;
export const VENDOR = (window && window.safeLoanConfig && window.safeLoanConfig.vendor) ? window.safeLoanConfig.vendor : process.env.REACT_APP_VENDOR ? process.env.REACT_APP_VENDOR : "latitude";
export const LENDER = (window && window.safeLoanConfig && window.safeLoanConfig.lender) ? window.safeLoanConfig.lender : process.env.REACT_APP_LENDER ? process.env.REACT_APP_LENDER : "[Lender Name]";

export const getIconForClass = (iconClass) => {
    switch (iconClass){
        case "Telephone": return <Telephone className={"mr-2"}/>;
        case "Film": return <Film className={"mr-2"}/>;
        case "GraphUp": return <GraphUp className={"mr-2"}/>;
        case "ArrowsCollapse": return <ArrowsCollapse className={"mr-2"}/>;
        case "Cash": return <Cash className={"mr-2"}/>;
        case "CashStack": return <CashStack className={"mr-2"}/>;
        case "CreditCard": return <CreditCard className={"mr-2"}/>;
        case "CloudRainHeavy": return <CloudRainHeavy className={"mr-2"}/>;
        case "ShieldCheck": return <ShieldCheck className={"mr-2"}/>;
        case "ArrowClockwise": return <ArrowClockwise className={"mr-2"}/>;
        case "Wallet": return <Wallet className={"mr-2"}/>;
        case "Wallet2": return <Wallet2 className={"mr-2"}/>;
        case "PatchQuestion": return <PatchQuestion className={"mr-2"}/>;
        default:
            break;
    }
}

