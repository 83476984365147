import {ArrowLeft} from "react-bootstrap-icons";
import React from "react";
import {useHistory} from "react-router-dom";
import {useApplicationContext} from "../context/AppContext";


const ResultsBackButton = () => {
    const history = useHistory();
    const {auth, saveEvent, setCurrentLocation} = useApplicationContext();
    return (
        <button data-testid="goBack" type={"button"} className={"in-page-nav mb-5"}
                onClick={() => {
                    setCurrentLocation(`/${auth.customerId}`);
                    saveEvent('back');
                    history.push(`/${auth.customerId}`);
                }}><ArrowLeft
            className="mr-1"/>Go back and change my answers
        </button>
    )
}

export default ResultsBackButton;