import React from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import {useSweetAlertContext} from "../context/SweetAlertContext";


const ErrorDialog = () => {
    const {sweetAlertConfig,setSweetAlertConfig} = useSweetAlertContext();


    const onConfirm = () => {
        if(sweetAlertConfig.closeFunction){
            sweetAlertConfig.closeFunction();
        }else {
            setSweetAlertConfig(prevState => {
                const currentState = prevState;
                currentState.open = false;
                return {...prevState, ...currentState}
            })
        }
    }

    return (sweetAlertConfig && sweetAlertConfig.open && sweetAlertConfig.type !== 'success' && <SweetAlert
        error
        title={sweetAlertConfig.title}
        onConfirm={() => onConfirm()}
        onCancel={onConfirm}
        timeout={sweetAlertConfig.timeout}
        openAnim={{ name: 'showSweetAlert', duration: 1000 }}
        closeOnClickOutside={false}
        allowEscape={false}
        dependencies={[]}
    >
        {() => (
            <form>
                {sweetAlertConfig.message}
            </form>
        )}
    </SweetAlert>)
}

export default ErrorDialog;